<template>
  <footer class="grow bg-white">
    <section class="border-y border-gray-200 bg-gray-50 p-4 sm:p-6 lg:p-10">
      <div class="mx-auto max-w-screen-xl">
        <div class="flex flex-col lg:flex-row-reverse">
          <div class="w-full lg:mt-0 lg:max-w-lg">
            <div class="py-1 text-xl font-bold text-gray-900">
              Om Skaffa Hund
            </div>
            <p class="mt-2 text-sm text-gray-700 sm:text-base">
              Skaffa Hund är din trygga partner i varje steg av valpresan. Vi hjälper dig hitta valpar i närheten från ansvarsfulla uppfödare du kan lita på. Utforska hundratals valpannonser, kom i direktkontakt med vårt community av passionerade uppfödare och påbörja din hundresa redan idag – vi finns här för dig genom hela processen!
              <br><br>
              Hos oss hittar du också praktiska hjälpmedel som <a :href="window.route('breedGuideResults.create')">Rasguiden</a> och utförlig information om <a :href="window.route('breeds.index')">alla hundraser</a>, samt tips för allt från vardagslydnad till träning och omsorg. Tillsammans gör vi det enkelt och roligt att skaffa hund!
            </p>
            
            <div class="mt-3 flex">
              <apple-reviews-card />
            </div>
          </div>

          <div class="mt-6 grow lg:mt-0">
            <div class="py-1 text-xl font-bold text-gray-900">
              Populära sökningar
            </div>

            <div class="mt-2 grid grid-cols-2 gap-6 sm:grid-cols-4 lg:mt-4">
              <div>
                <div class="mb-3 font-bold text-black">
                  Raser
                </div>
                
                <div v-for="breed in breeds" :key="breed.value" class="font-hairline truncate pr-1 leading-loose text-gray-700">
                  <a class="hover:underline" :href="window.route('ads.breeds', { slug: breed.value })">
                    {{ breed.label }}
                  </a>
                </div>
              </div>

              <div>
                <div class="mb-3 font-bold text-black">
                  Hundstorlek
                </div>
                <div v-for="size in sizes" :key="size.value" class="font-hairline truncate pr-1 leading-loose text-gray-700">
                  <a class="hover:underline" :href="window.route('ads.index', { size: size.value })">
                    {{ size.label }}
                  </a>
                </div>
              </div>
              
              <div>
                <div class="mb-3 font-bold text-black">
                  Kön & Ålder
                </div>
                <div v-for="gender in genders" :key="gender.value" class="font-hairline truncate pr-1 leading-loose text-gray-700">
                  <a class="hover:underline" :href="window.route('ads.index', { gender: gender.value })">
                    {{ gender.label }}
                  </a>
                </div>
                
                <div v-for="age in ages" :key="age.value" class="font-hairline truncate pr-1 leading-loose text-gray-700">
                  <a class="hover:underline" :href="window.route('ads.index', { age: age.value })">
                    {{ age.label }}
                  </a>
                </div>
              </div>
              
              <div>
                <div class="mb-3 font-bold text-black">
                  Säljare
                </div>
                <div v-for="seller in sellers" :key="seller.value" class="font-hairline truncate pr-1 leading-loose text-gray-700">
                  <a class="hover:underline" :href="window.route('ads.index', { seller: seller.value })">
                    {{ seller.label }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-white p-4 sm:p-6 lg:p-10">
      <div class="mx-auto max-w-screen-xl">
        <div class="grid grid-cols-2 gap-y-6 md:grid-cols-4 md:gap-0 lg:grid-cols-5">
          <div class="hidden lg:block">
            <a :href="window.route('home')">
              <img
                class="h-10 w-auto sm:h-14"
                src="@assets/Logo-Green-Horizontal.svg"
                alt="Skaffa Hund Logo"
                loading="lazy"
              >
            </a>
          </div>

          <div>
            <div class="mb-3 font-bold text-black">
              För köpare
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('keywordPages.buyDog')">
                Hur går hundköp till?
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('information.buyer') + '#premium'">
                Premium
              </a>
              <span class="ml-0.5 inline-flex size-5 items-center justify-center rounded-full bg-purple-100">
                <fa-icon class="text-2xs text-purple-500" icon="crown" type="fas" />
              </span>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('ownershipCost.show')" title="Vad kostar det att ha en hund?">
                Kostnadskalkylator
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('tags.show', { tag: 'overvager-du-hund' })">
                Överväger du hund?
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('tags.show', { tag: 'hitta-ratt-hund' })">
                Hitta rätt hund
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('tags.show', { tag: 'upphamtning-och-kop' })">
                Upphämtning och köp
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('tags.show', { tag: 'du-och-din-hund' })">
                Du och din hund
              </a>
            </div>
          </div>

          <div>
            <div class="mb-3 font-bold text-black">
              För säljare
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('information.seller')">
                Introduktion
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('kennels.create')">
                Sälj som kennel
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('insurances.create')">
                Kullförsäkring
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('tags.show', { tag: 'din-forsta-forsaljning' })">
                Din första försäljning
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('tags.show', { tag: 'avel-och-parning' })">
                Avel och parning
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('information.safetyPack')">
                Trygghetspaketet
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('information.safePayments')">
                Säker Betalning
              </a>
            </div>
          </div>

          <div>
            <div class="mb-3 font-bold text-black">
              Hitta
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('kennels.map')">
                Kennlar
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('breeds.index')">
                Hundraser
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('quizzes.index')">
                Kunskapstest
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('breedGroups.index')">
                Rasgrupper
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('breedGuideResults.create')">
                Rasguiden
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('insuranceGuide.show')">
                Försäkringsguiden
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('shop')">
                Butiken
              </a>
            </div>
          </div>

          <div>
            <div class="mb-3 font-bold text-black">
              Skaffa Hund
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('about')">
                Om oss
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('help')">
                Vanliga frågor
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('contact')">
                Kontakta oss
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('reviews')">
                Vad tycker våra kunder?
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-700">
              <a class="hover:underline" :href="window.route('partners')">
                Samarbetspartners
              </a>
            </div>
          </div>
        </div>

        <div class="-mx-4 mt-12 justify-between sm:mx-0 sm:flex md:mt-24 lg:mt-32">
          <div>
            <div class="flex items-center justify-center md:justify-start">
              <a rel="nofollow" :href="window.route('terms')" class="font-hairline text-xs text-gray-700 hover:underline sm:text-sm">
                Användarvillkor
              </a>
              <span class="mx-1 text-gray-700">&middot;</span>
              <a rel="nofollow" :href="window.route('privacyPolicy')" class="font-hairline text-xs text-gray-700 hover:underline sm:text-sm">
                Personuppgiftshantering
              </a>
              <span class="mx-1 text-gray-700">&middot;</span>
              <a rel="nofollow" :href="window.route('cookiePolicy')" class="font-hairline text-xs text-gray-700 hover:underline sm:text-sm">
                Cookie policy
              </a>
            </div>

            <div class="font-hairline text-center text-xs text-gray-600 sm:text-left sm:text-sm">
              © Copyright {{ year }} Dogs Unleashed AB. Org.nr.: 559049-6807
            </div>
          </div>

          <div class="mt-6 flex items-center justify-center space-x-2 sm:mt-0">
            <a 
              href="https://www.facebook.com/SkaffaHund.se/"
              class="rounded bg-blue-600 px-1 text-xl text-white"
              title="Skaffa Hund på Facebook"
              target="_blank"
            >
              <fa-icon icon="facebook" type="fab" />
            </a>
            
            <a 
              href="https://instagram.com/skaffahund/"
              class="rounded bg-purple-500 bg-gradient-to-tr from-orange-500 to-pink-500 px-1 text-xl text-white"
              title="Skaffa Hund på Instagram"
              target="_blank"
            >
              <fa-icon icon="instagram" type="fab" />
            </a>

            <a href="https://apps.apple.com/se/app/skaffa-hund/id1476715046" target="_blank" rel="noopener">
              <img
                class="h-8 w-auto"
                src="@assets/App-Store.svg"
                alt="App Store Link"
                loading="lazy"
              >
            </a>

            <a href="https://play.google.com/store/apps/details?id=se.skaffahund" target="_blank" rel="noopener">
              <img
                class="h-8 w-auto"
                src="@assets/Google-Play.svg"
                alt="Google Play Link"
                loading="lazy"
              >
            </a>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { DogAge, Gender, Seller } from "@/@types";
  import { enumOptions } from "@/library/enums";
  import AppleReviewsCard from "@/components/AppleReviewsCard.vue";
  import FaIcon from "@/components/FaIcon.vue";

  export default defineComponent({
    components: { FaIcon, AppleReviewsCard },
    computed: {
      Seller() {
        return Seller
      },
      Gender() {
        return Gender
      },
      DogAge() {
        return DogAge
      },
      year() {
        return new Date().getFullYear();
      },
      breeds() {
        return [
          { value: 'labrador-retriever', label: 'Labrador Retriever' },
          { value: 'schafer', label: 'Tysk Schäferhund' },
          { value: 'golden-retriever', label: 'Golden Retriever' },
          { value: 'cocker-spaniel', label: 'Cocker Spaniel' },
          { value: 'fransk-bulldogg', label: 'Fransk Bulldogg' },
          { value: 'pomeranian', label: 'Pomeranian' },
          { value: 'chihuahua', label: 'Chihuahua' },
        ]
      },
      sellers() {
        return enumOptions('Seller').filter(o => o.value !== Seller.Organization)
      },
      sizes() {
        return enumOptions('Size');
      },
      genders() {
        return enumOptions('Gender');
      },
      ages() {
        return enumOptions('DogAge');
      },
    },
  })
</script>
